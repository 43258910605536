<template>
  <div class="app-container">
    <addDialog
      :type.sync="type"
      :form="form"
      :radio.sync="radio"
      :radio2.sync="radio2"
      @refresh="refresh"
      @resetFields="resetFields"
      ref="dialog"
      @handleCopied="handleCopied"
    />
    <div class="continent-warpper" v-p="['externaladmin:fedex:formwork:search']">
      <span class="mr10">运费模板分类:</span>
      <ul
        v-for="item in categoryList"
        :key="item.index"
        class="whole-warpper"
        :class="[{ active: currentCategory === item.category }]"
        @click="onSelect(item.category)"
      >
        <li>{{ item.type }}</li>
      </ul>
      <span class="ml10">运费模板状态：</span>
      <span :class="[{ active: selectedIndex === -1 }, 'whole-warpper']" @click="handleSelectedAll">全部</span>
      <ul
        v-for="(item, index) in list"
        :key="index"
        class="whole-warpper"
        :class="[{ active: selectedIndex === index }]"
        @click="handleSelected(index)"
      >
        <li>{{ item.type }}</li>
      </ul>
      <span style="margin-left: 8px">模板名称：</span>
      <el-input
        v-model="search"
        style="width: 180px"
        placeholder="输入模板名称"
        size="mini"
        clearable
        @clear="searchModel"
        @change="searchModel"
      ></el-input>
      <el-button type="primary" icon="el-icon-search"></el-button>
    </div>
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
    <CommonTable class="operation" :selection="false" :tableLoading="tableLoading" :cols="cols" :infoData="data">
      <template #stateSlot="{ scoped: row }"> {{ getState(row) }} </template>
      <template #updateTimeSlot="{ scoped: row }">
        {{ parseTime(row[row.prop]) }}
      </template>
      <slot>
        <el-table-column fixed="right" label="操作" width="350px" align="center">
          <template slot-scope="{ row }">
            <template v-if="row.isEnabled === 0">
              <span class="primary" @click="onEdit(row)" v-p="['externaladmin:fedex:formwork:edit']">编辑</span>
              <el-divider direction="vertical"></el-divider>
              <span class="primary" v-p="['externaladmin:fedex:formwork:enable']" @click="onOpen(row)">启用</span>
              <el-divider direction="vertical"></el-divider>
              <span class="primary" @click="onCopy(row)" v-p="['externaladmin:fedex:formwork:copy']">复制</span>
              <el-divider direction="vertical"></el-divider>
              <Popover v-p="['externaladmin:fedex:formwork:delete']" @sureHandler="onDelete(row)">
                <template #tip>
                  <p>您确定要删除模板么?</p>
                </template>
                <template #reference="{ scope: loading }">
                  <color-text-btn class="update-btn" :loading="loading" size="mini" type="danger">删除</color-text-btn>
                </template>
              </Popover>
            </template>
            <template v-if="row.isEnabled === 1">
              <span class="primary" v-p="['externaladmin:fedex:formwork:detail']" @click="onWatch(row)">查看</span>
              <el-divider direction="vertical"></el-divider>
              <span class="primary" @click="onCopy(row)" v-p="['externaladmin:fedex:formwork:copy']">复制</span>
              <el-divider direction="vertical"></el-divider>
              <span class="danger" v-p="['externaladmin:fedex:formwork:disable']" @click="onForbidden(row)">禁用</span>
            </template>
            <template v-if="row.isEnabled === 2">
              <span class="primary" @click="onDisableEdit(row)" v-p="['externaladmin:fedex:formwork:disableEdit']">编辑</span>
              <el-divider direction="vertical" v-p="['externaladmin:fedex:formwork:disableEdit']"></el-divider>
              <span class="primary" v-p="['externaladmin:fedex:formwork:detail']" @click="onWatch(row)">查看</span>
              <el-divider direction="vertical"></el-divider>
              <span @click="onCopy(row)" class="primary" v-p="['externaladmin:fedex:formwork:copy']">复制</span>
              <el-divider direction="vertical"></el-divider>
              <span class="primary" v-p="['externaladmin:fedex:formwork:enable']" @click="onOpen(row)">启用</span>
            </template>
          </template>
        </el-table-column>
      </slot>
    </CommonTable>
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
    <templateDialog :show.sync="show" :templateData="templateData" @handleForbidden="handleForbidden" />
  </div>
</template>

<script>
import templateDialog from './module/templateDialog.vue'
import { deleteTemplate, createTemplate, updateTemplate, getRelatedMailList, copy } from '@/api/template'
import { delMixin } from '@/mixins'
import addDialog from './module/addDialog.vue'
import { formworkListCols as cols } from '@/views/fedex/cols'
import { parseTime } from '@/utils'
import { mapGetters } from 'vuex'
import initDataMixin from '@/mixins/initData'
export default {
  name: 'formwork',
  mixins: [initDataMixin, delMixin],
  components: {
    addDialog,
    templateDialog
  },
  data() {
    return {
      cols,
      url: '/externaladmin/systemService/expressFreightTemplate/list',
      method: 'post',
      mergeData: {
        isDeleted: 0,
        orderItem: [{ asc: false, column: 'updateTime' }]
      },
      radio: 1,
      radio2: 1,
      type: 'add',
      currentData: {},
      form: {
        name: '',
        way: 1,
        category: 1
      },
      templateData: {},
      show: false,
      search: '',
      tm: '',
      selectedIndex: -1,
      currentCategory: 1,
      query: {},
      categoryList: [
        { type: '国际', category: 1, index: 'international' },
        { type: '国内', category: 2, index: 'domestic' }
      ],
      list: [
        { type: '未启用', index: 0 },
        { type: '已启用', index: 1 },
        { type: '已停用', index: 2 }
      ],
      copy: {}
    }
  },
  computed: {
    ...mapGetters(['continentDictCode'])
  },
  deactivated() {
    this.$refs.dialog.visible = false
  },
  mounted() {
    this.onSelect(1)
  },
  methods: {
    parseTime,
    resetFields() {
      this.radio2 = 1
      this.form = {
        name: '',
        way: 1,
        category: 1
      }
    },
    redirect(row, path = '/fedex/formwork/settings') {
      this.$router.push({
        path,
        query: {
          id: row.id,
          templateName: row.templateName,
          calculateMode: row.calculateMode,
          volumeDivisor: row.volumeDivisor,
          weight: row.isByVolumetricWeight,
          type: this.type,
          category: row.templateCategory,
          status: row.isEnabled
        }
        // query: { res: JSON.stringify(this.copy), type: this.type }
      })
    },
    onEdit(row) {
      this.type = 'edit'
      this.redirect(row)
      console.log(this.page)
    },

    onDisableEdit(row) {
      this.redirect(row, '/fedex/formwork/settingByDisable')
    },

    onOpen(row) {
      const copy = JSON.parse(JSON.stringify(row))
      copy.isEnabled = 1
      // this.copy = row
      // this.copy.isEnabled = 1
      const res = updateTemplate(copy).then(() => {
        this.$message.success('模板启用成功')
        this.init()
      })
      console.log(res)
    },
    onCopy(row) {
      this.type = 'copy'
      this.form.name = row.templateName + '副本'
      this.copy = row
      this.$refs.dialog.visible = true
    },
    async onDelete(row) {
      const index = [row.id]
      try {
        await deleteTemplate({ idList: index })
        this.query.templateCategory = row.templateCategory
        this.init()
        this.$message.success('模板删除成功')
      } catch (error) {}
    },
    onWatch(row) {
      this.type = 'watch'
      this.redirect(row)
    },
    handleForbidden() {
      // this.copy = this.currentData
      // this.copy.isEnabled = 2
      const copy = JSON.parse(JSON.stringify(this.currentData))
      copy.isEnabled = 2
      updateTemplate(copy).then(() => {
        this.$message.success('模板禁用成功')
        this.init()
        this.show = false
      })
    },
    onForbidden(row) {
      this.currentData = row
      this.show = true
      console.log(this.templateData)
      getRelatedMailList({ id: row.id }).then((res) => {
        this.templateData = res.detail
      })
      console.log(this.templateData)
    },
    handleSelected(index) {
      this.selectedIndex = index
      this.query.isEnabled = index
      this.query.templateName = this.tm
      this.searchChange()
    },
    onSelect(category) {
      this.currentCategory = category
      //添加分类搜索query
      this.query.templateCategory = category
      this.searchChange()
    },
    handleSelectedAll() {
      this.selectedIndex = -1
      this.query.templateName = this.tm
      this.query.isEnabled = undefined
      this.searchChange()
    },
    async handleCopied() {
      this.copy.templateName = this.form.name
      delete this.copy.updateTime
      delete this.copy.isEnabled
      // await createTemplate(this.copy)
      //   .then(() => {
      //     this.init();
      //     this.$refs.dialog.visible = false;
      //     this.$message.success("模板复制成功");
      //   })
      //   //解决模板名称重复导致的列表坍塌
      //   .catch(this.init());
      let currentId = 0
      const templateName = this.copy.templateName
      await copy({ id: this.copy.id, templateName })
        .then((res) => {
          currentId = res.detail
          this.init()
          this.$refs.dialog.visible = false
          this.$message.success('模板复制成功')
        })
        .catch(this.init())
      this.$router.push({
        path: '/fedex/formwork/settings',
        query: {
          id: currentId,
          templateName: this.copy.templateName,
          calculateMode: this.copy.calculateMode,
          volumeDivisor: this.copy.volumeDivisor,
          isByVolumetricWeight: this.copy.isByVolumetricWeight,
          weight: this.copy.isByActualWeight,
          type: this.type
        }
        // query: { res: JSON.stringify(this.copy), type: this.type }
      })
    },
    searchModel() {
      this.query.templateName = this.search
      this.tm = this.search
      this.searchChange()
    },
    refresh() {
      this.init()
    },
    getState(data) {
      switch (data.isEnabled) {
        case 0:
          return '未启用'
          break
        case 1:
          return '已启用'
          break
        case 2:
          return '已停用'
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  display: flex;
  flex-direction: column;
  .operation {
    .line {
      display: inline-block;
      margin-right: 8px;
      width: 1px;
      height: 12px;
      background: #dcdee0;
    }
    .primary {
      color: #3841db !important;
      //margin-right: 8px;
      cursor: pointer;
    }
    .danger {
      color: #f54a45;
      cursor: pointer;
    }
  }
  .continent-warpper {
    //min-width: 1200px;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    background-color: #f0f2f5;
    padding: 20px;
    .el-button {
      margin-left: 8px;
      width: 48px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
    }
    .active {
      color: #3841db;
      border: 1px solid #3841db !important;
    }
    .whole-warpper {
      padding: 10px;
      border: 1px solid #dcdee0;
      border-radius: 2px;
      margin-right: 12px;
      cursor: pointer;
      li {
        list-style: none;
      }
    }
  }
  .addButton {
    ::v-deep {
      width: 90px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .continent-warpper {
    display: flex;
  }
}
</style>
